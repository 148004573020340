<script setup lang="ts">
const router = useRouter();

const route = useRoute();

const verified = !!route.query.verified;

onMounted(() => {
  if (!verified) return;

  router.replace({
    query: { ...route.query, verified: undefined },
  });
});
</script>

<template>
  <div v-if="verified" class="verified-message">
    Jouw e-mail is geverifieerd, bedankt!
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.verified-message {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  padding: 1.5rem;
  background-color: #1a202c;
  color: #ffffff;
  font-weight: 600;
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.05),
    0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
}
</style>
