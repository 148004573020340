<script setup>
useHead({
  titleTemplate: (title) => `Maaike - ${title}`,
  charset: "utf-8",
  meta: [{ name: "description", content: "De slimme leefstijlcoach" }],
  link: [
    { rel: "manifest", href: "/manifest.webmanifest" },
    { rel: "icon", href: "/favicon.ico", sizes: "any" },
    { rel: "icon", href: "/favicon.svg", type: "image/svg+xml" },
    { rel: "apple-touch-icon", href: "/apple-touch-icon-180x180.png" },
  ],
});
</script>

<template>
  <div class="main">
    <slot />
    <AuthVerifiedMessage />
    <Icon name="search" class="preload-icons" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/abstracts" as *;

.main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.preload-icons {
  position: fixed;
  top: -100px;
  left: -100px;
}
</style>
